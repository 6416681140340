import React from 'react'

const Location = () => {
	return (
		<div>
			<div className='container-fluid'>
				<div className='row'>
					<iframe
						src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3320.2623876247585!2d73.02450867520938!3d33.67626943749008!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x38dfbe27945f675f%3A0x422607304b7c954c!2sI%20%26%20T%20Center%2C%20I%26T%20Centre%2C%20Street%2039%2C%20G-10%2F4%20G%2010%2F4%20G-10%2C%20Islamabad%2C%20Islamabad%20Capital%20Territory%2074400%2C%20Pakistan!5e0!3m2!1sen!2s!4v1718821847917!5m2!1sen!2s'
						width='600'
						height='450'
						style={{ border: '0' }}
						allowfullscreen=''
						loading='lazy'
						referrerpolicy='no-referrer-when-downgrade'
					></iframe>
				</div>
			</div>
		</div>
	)
}

export default Location

import React from 'react'
import office from '../../Assests/Office.jpg'

const Aboutus = () => {
	return (
		<div className='Aboutus' id='contactus'>
			<div className='container'>
				<div className='row text-center my-4'>
					<h1 className='taxi-heading mt-5  '>OUR OFFICE </h1>
				</div>
				<div className='row my-5'>
					<div className='col-md-6 mt-3'>
						<div>
							<h4 className='taxi-heading pt-5'> Our Address:</h4>
						</div>
						<p className='taxi-para'>First floor, Block 39, I&T centre, Street 37, G-10/4 Islamabad</p>
						<div>
							<h4 className='taxi-heading'> Contact Number:</h4>
						</div>
						<p className='taxi-para'>+92 339 3962483</p>
						<div>
							<h4 className='taxi-heading'>Email Address:</h4>{' '}
						</div>
						<p className='taxi-para'>
							Email:
							<span className='primary-blue-color'>
								{' '}
								&nbsp;
								<a className='email' href='mailto:info@blackbridgesolutions.com'>
									info@blackbridgesolutions.com
								</a>
							</span>
						</p>
					</div>
					<div className='col-md-6 mb-5'>
						<img src={office} className='img-fluid hero-img' alt='' />
					</div>
				</div>
			</div>
		</div>
	)
}

export default Aboutus

import React from 'react'
import Logo from '../../Assests/logo1.png'

const Footer = () => {
	return (
		<div className='Footer'>
			<div className='container'>
				<div className='row'>
					<div className='col-md-4 mt-5'>
						<div className='text-start'>
							<img className='img-fluid header-img ' style={{ width: '220px', height: '220px' }} src={Logo} alt='' />
						</div>
						<p className='para '>
							Welcome to Black Bridge Solutions, a leading BPO company dedicated to excellence in team and execution. We are passionate about delivering exceptional service, innovative solutions, and unparalleled support. Our mission is to empower businesses to achieve their full potential through
							our expertise, cutting-edge technology, and customer-centric approach.
						</p>
					</div>
					<div className='col-md-2 mt-5'>
						<div className='aboutus mb-3 '>About Us</div>
						<ul className='footeritem '>
							<li>Home</li>
							<li>Expertise</li>
							<li>Taxi Dispatch</li>
							<li>Customer Support</li>
							<li>Our Achievements</li>
							<li>FAQ's</li>
							<li>Contact Us</li>
						</ul>
					</div>
					<div className='col-md-3 my-5'>
						<div className='aboutus mb-3'>Customer Service</div>
						<ul className='footeritem'>
							<li>24/7 Support</li>
							<li>FAQs</li>
							<li>Live Chat</li>
							<li>Help Center</li>
							<li>Service Status</li>
						</ul>
					</div>
					<div className='col-md-3 my-5'>
						<div className='address'>Our Address:</div>
						<p className='para'>First floor, Block 39, I&T centre, Street 37, G-10/4 Islamabad</p>
						<div className='heading'>Contact Number:</div>
						<p className='para'>
							<a href='tel:+923393962483 ' className='email'>
								+92 339 3962483
							</a>
						</p>
						<div className='heading'>Email Address:</div>
						<p className='para'>
							Email:
							<span className='primary-blue-color'>
								{' '}
								&nbsp;
								<a className='email' href='mailto:info@blackbridgesolutions.com'>
									info@blackbridgesolutions.com
								</a>
							</span>
						</p>
					</div>
				</div>
			</div>
		</div>
	)
}

export default Footer
